<script>
export default {
  name: "RoomEmpty"
}
</script>

<template>
  <div class="user-chat w-100 overflow-hidden">
    <div class="d-lg-flex">
      <div class="m-auto col-md-8 col-lg-6 col-xl-6">
        <div class="card" style="margin-top: 120px;">
          <div class="card-body p-4">
            <div class="text-center px-4">
              <h1>Choose chat</h1>
            </div>
            <div class="text-center pt-4">
              <p>Please, choose chat from left section to start messaging</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>